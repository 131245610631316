import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/2023/GraduationDay/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/2023/GraduationDay/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/2023/GraduationDay/3.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const GraduationDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/GraduationDay/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/GraduationDay/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/GraduationDay/3.webp`;

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 2,
        }, 
         
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                Graduation Day 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                               Date: 19 JANUARY 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The Graduation Day of National Public School, Yeshwanthpur, was celebrated on Friday, 19 January 2024 with great exultation and jubilation. The highlight of the event was the academic procession of 63 graduands adorned in their ceremonial robes, who have all flowered into responsible and determined individuals. The event formally commenced with the soulful rendition of the invocation song and the mesmerizing dance by the talented junior students.   
                                    <br></br>
                                    The Guest of Honour was Dr K Balamurugan IRS and is currently working as the additional Director General (Systems and Data Management) at the central Board for indirect Taxes and Customs, Bengaluru. In his key note convocation address, he connected with the entire audience by sharing snippets of his incredible life journey filled with humility and the insights which shaped his future course of life.  He exhorted students that they were all blessed and they should make the best of the opportunity bestowed upon them to   carve their futures. The Chairman of National Public School, Yeshwanthpur, Dr Shivashankar, while congratulating the students urged that they should continue to work hard to achieve greater success in life and the Graduation is just the beginning of their journey. 
                                    <br></br>
                                    The member of the Management, Dr Sanjana Shivshanker, gave an impactful talk on the importance of priority in life and how to work towards it without any distractions. The Principal Ms Sheeja Manoj, in her Declaration address, extended her heartfelt appreciation to the Parents and staff who have been instrumental in moulding the Graduands. She also placed on record that this batch of graduands has been very sincere, genuine and respectful towards all and they will always hold a special place in our hearts for their humility, discipline and exuberance. 
                                    <br></br>
                                    She also expressed hope that the knowledge and wisdom attained at the Alma mater, will guide each one of them to lead a successful life in the outside world. The beaming countenances of the graduands stood testimony that the day not only celebrated academic achievements but also forged friendships, lessons learnt and the personal growth over the years to come. 
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “Go confidently in the direction of your dreams. Live the life you have imagined.”

                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default GraduationDay2023;